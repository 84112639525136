
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import InformationDDC from "./InformationDDC.vue";
import formatUtil from "../../mixins/roundValues";
import highlightReports from "../../mixins/highlightReports";
import { formatFinancialAmounts, formatTwoDecimal } from "../../utils/format";

export default Vue.extend({
  name: "vWD-Severity",
  components: { InformationDDC },
  mixins: [formatUtil, highlightReports],
  data: () => ({
    data: [],
    isLoading: false,
  }),
  computed: {
    maxtableHeight() {
      return window.screen.height - window.screen.height * 0.2;
    },
    title() {
      return i18n.t("reports.ddcreports.listReports.vWD-Severity");
    },
    headers() {
      return [
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.n"),
          value: "n",
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.region"),
          value: "region",
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.surveyYearName"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.data_year"),
          value: "dataYear",
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWD-Severity.previoussurveyYearName"
          ),
          value: "previousSurveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.previousDataYear"),
          value: "previousDataYear",
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.currentvWDTotal"),
          value: "current_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.previousvWDTotal"),
          value: "previous_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.severeTotal"),
          value: "current_Severe_Type_3_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.previousSevereTotal"),
          value: "previous_Severe_Type_3_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWD-Severity.severeTotalDifferencePercentage"
          ),
          value: "severe_Type_3_Total_Perc_Diff",
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.therapyTotal"),
          value: "current_Therapy_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t("reports.ddcreports.vWD-Severity.previousTherapyTotal"),
          value: "previous_Therapy_Total",
          formatter: (val: number): number | string =>
            formatFinancialAmounts(val),
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWD-Severity.therapyTotalDifferencePercentage"
          ),
          value: "therapy_Total_Perc_Diff",
        },
      ];
    },
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      axios
        .get(`Report/DDC/SevereVonWillebrand`, {
          params: { year: this.yearSelected },
        })
        .then((res) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `Report/DDC/SevereVonWillebrand/export?year=${this.yearSelected}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.yearSelected) {
      this.getData();
    }
  },
  watch: {
    yearSelected() {
      this.getData();
    },
  },
});
